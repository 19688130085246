<template>
  <div class="container-fluid">
    <div class="row home-banner">
      <div class="col-12 text-center">
        <div class="text-banner">
          <h1>IRTC Decision Tool</h1>
          <h2 class="mt-4">
            Better understand and mitigate raw material criticality in your
            company
          </h2>
          <router-link :to="{ name: 'Tools' }">
            <button class="submit-sec-btn mt-4">Start the tool</button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row py-5">
      <div class="col-12 col-lg-10 m-auto">
        <h2 class="purple-text mb-4">About the IRTC Project</h2>
        <p>
          The International Round Table on Materials Criticality, IRTC, is an
          international network of experts in Critical Raw Materials. The first
          phase of the project (2018 – 2020) laid the cornerstone for a
          long-lasting international network via the organization of Round Table
          discussions at international conferences Vancouver (Canada), Tokyo
          (Japan), and San Antonio (Texas, US) around the topics “How
          methodology determines what is critical”, “Criticality and the
          Circular Economy”, and “How Industry Manages Criticality”. These
          discussions brought forth several joint publications, presenting
          research on differences and commonalities of different approaches on
          criticality, as well as implications for industries, policymakers, and
          the Circular Economy.
        </p>
        <p>
          In 2020, the IRTC project started its second phase as “IRTC-Business”
          with an increased focus on supporting industries in the identification
          and managing of Critical Raw Materials, via (virtual) round table
          discussions and workshops with the support of an Industry Focus group,
          the publication of guidance documents, and the development of this
          web-tool for raw material criticality evaluation and mitigation.
        </p>
        <div class="mt-4">
          <router-link :to="{ name: 'About' }"
            ><button class="submit-btn mr-1">See more</button></router-link
          >

          <a href="https://irtc.info/contact/" target="_blank"
            ><button class="submit-sec-btn mt-2">
              Subscribe to the newsletter
            </button></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style lang="scss">
.home-banner {
  background: url('../assets/img/chemistry-bg.jpg') center center no-repeat;
  background-size: cover;
  min-height: 20rem;
  .text-banner {
    max-width: 45%;
    margin: 0 auto;
    padding: 3rem 0rem;
    h2 {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
}
.img-center {
  .img-fluid {
    height: 10rem;
  }
}

@include bp-down(lg) {
  .home-banner {
    .text-banner {
      max-width: 90%;
    }
  }
}

@include bp-down(md) {
  .home-banner {
    .text-banner {
      max-width: 95%;
    }
  }

  .img-center {
    .img-fluid {
      height: 6rem;
    }
  }
}
</style>
