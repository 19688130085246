<template>
  <footer class="container-fluid light-bg">
    <div class="row credentials-row mt-4 mb-4">
      <div class="col-12 col-lg-6">
        <h5>About IRTC</h5>
        <div class="social-links">
          <a
            href="https://www.linkedin.com/company/irtc-international-round-table-on-materials-criticality"
            target="_blank"
            ><i class="bi bi-linkedin"></i
          ></a>
          <a href="https://twitter.com/foundationesm" target="_blank"
            ><i class="bi bi-twitter"></i
          ></a>
          <a href="https://irtc.info/" target="_blank"
            ><i class="bi bi-globe2"></i
          ></a>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <h5>About WeLOOP</h5>
        <div class="social-links">
          <a href="https://www.linkedin.com/company/weloop" target="_blank"
            ><i class="bi bi-linkedin"></i
          ></a>
          <a href="https://twitter.com/weloop_" target="_blank"
            ><i class="bi bi-twitter"></i
          ></a>
          <a href="https://www.weloop.org/" target="_blank"
            ><i class="bi bi-globe2"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="row img-row">
      <div class="col-12">
        <img class="img-fluid img-desk" src="@/assets/img/eit-desk.png" />
        <img class="img-fluid img-mob" src="@/assets/img/eit-mobile.png" />
      </div>
    </div>
    <div class="row copyright-row">
      <div class="col-12">
        <div class="footer-copyright">
          <p class="copyright">
            <span>
              &copy; Copyright {{ getDate() }} |
              <a target="_blank" href="https://irtc.info/">IRTC Business</a>
              &amp; <a target="_blank" href="https://weloop.org">WeLOOP </a>|
              All rights reserved
            </span>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  methods: {
    getDate() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss">
footer {
  text-align: center;
  font-family: $accent-font;
  font-size: 0.8rem;
  font-weight: 500;
  border-top: 1px solid $primary-70;
  padding-bottom: 0.5rem;
  position: relative;
  bottom: 0;
  margin-top: 3rem;
  .img-row {
    .img-desk {
      display: none;
    }
    .img-mob {
      width: 50%;
    }
  }
  .copyright-row {
    margin-top: 2rem;
  }
  .credentials-row {
    h5 {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    .social-links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      a {
        color: $dark-text;
        width: 25px;
        transition: all 0.2s ease-in-out;
        &:nth-child(1),
        &:nth-child(2) {
          margin-right: 1rem;
        }
        i {
          font-size: 25px;
        }
        &:hover {
          color: $primary;
          cursor: pointer;
        }
      }
    }
  }
}

@include bp-up(md) {
  footer {
    .img-row {
      .img-mob {
        width: 40%;
      }
    }
  }
}

@include bp-up(lg) {
  footer {
    .img-row {
      .img-desk {
        display: block;
        width: 40%;
        margin: 0 auto;
      }
      .img-mob {
        display: none;
      }
    }
  }
}
</style>
