<template>
  <AppNavbar />
  <main>
    <router-view />
  </main>
  <AppFooter />
  <CookieBanner />
  <el-dialog v-model="showModal" title="Mobile navigation detected">
    <p>
      We've detected that you are using the application from a mobile device.<br />
      This application is unfortunately not suited for a mobile navigation.<br />
      Please visit the application from a laptop or a computer for the best
      experience.
    </p>
    <p>Thank you.</p>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showModal = false">I understood</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import AppNavbar from '@/components/nav/Navbar';
import AppFooter from '@/components/nav/Footer';
import CookieBanner from '@/components/rgpd/CookieBanner';

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
    CookieBanner
  },
  data() {
    return {
      showModal: false,
    };
  },
  created() {
    if (window.matchMedia('(max-width: 778px)').matches) {
      this.showModal = true;
    }
  },
};
</script>
