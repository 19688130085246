<template></template>

<script>
import { ElNotification } from 'element-plus';

export default {
  name: 'CookieBanner',
  data() {
    return {
      showBanner: false,
      cookie: {
        consent: true,
        date: new Date().toString(),
      },
    };
  },
  created() {
    const consent = this.$cookies.get('user_consent');

    if (!consent) this.openBanner();
  },
  methods: {
    setAnalytics() {
      this.$cookies.set('user_consent', JSON.stringify(this.cookie), '30d');
      this.$matomo.rememberCookieConsentGiven();
    },
    openBanner() {
      return ElNotification({
        message:
          'This website uses cookies to track traffic and monitor its performance.',
        duration: 0,
        position: 'bottom-left',
        onClose: () => {
          this.setAnalytics();
        },
      });
    },
  },
};
</script>

<style></style>
