<template>
  <div class="container-fluid bg-container"></div>
  <div class="container tools-container">
    <div class="row tools-row">
      <div class="col-12 col-xl-6">
        <router-link :to="{ name: 'Exploration' }" class="card card-hover">
          <h3>Exploration</h3>
          <h4>Overview of the Criticality Model</h4>
          <p>Explore and discover the criticality model</p>
          <span class="link-text">
            Start the tool
            <AppArrow />
          </span>
        </router-link>
      </div>
      <div class="col-12 col-xl-6">
        <router-link :to="{ name: 'Specific' }" class="card card-hover">
          <h3>Specific assessment</h3>
          <h4>Potential concerns for your company</h4>
          <p>Learn about the potential concerns for your company</p>
          <span class="link-text">
            Start the tool
            <AppArrow />
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tools',
};
</script>

<style lang="scss" scoped>

.tools-container {
  display: flex;
  flex-direction: column;
  transform: translateY(-7rem);
  .tools-row {
    display: flex;
    flex-direction: row;
    .card-hover {
      margin-bottom: 1rem;
      background-color: $light-bg;
      height: 15rem;
      padding: 1.75rem 1.75rem;
      transition: transform 0.45s ease, background 0.45s ease;
      border: 1px solid $primary;
      border-radius: 0;
      box-shadow: 0 1.5em 2.5em -0.5em rgba(#000000, 0.1);
      h3 {
        color: $dark-text;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1;
        padding-bottom: 0.5em;
        margin: 0 0 0.142857143em;
        border-bottom: 2px solid $secondary;
        transition: color 0.45s ease, border 0.45s ease;
        text-align: left;
      }
      h4 {
        color: #627084;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.1em;
        margin: 0 0 1.777777778em;
        transition: color 0.45s ease;
      }
      p {
        opacity: 0;
        color: #ffffff;
        line-height: 1.8;
        margin: 0 0 1.25em;
        transform: translateY(-1em);
        transition: opacity 0.45s ease, transform 0.5s ease;
      }
      .link-text {
        display: block;
        color: $secondary;
        font-size: 1rem;
        text-align: end;
        line-height: 1.2;
        margin: auto 0 0;
        transition: color 0.45s ease;
        svg {
          margin-left: 0.5em;
          transition: transform 0.6s ease;
          path {
            transition: fill 0.45s ease;
          }
        }
      }
      &:hover {
        background: $primary;
        transform: scale(1.02);
        h3 {
          color: #ffffff;
          border-bottom-color: $orange;
        }
        h4 {
          color: #ffffff;
        }
        p {
          opacity: 1;
          transform: none;
        }
        .link-text {
          color: #fff;
          svg {
            animation: point 1.25s infinite alternate;
            path {
              fill: $yellow;
            }
          }
        }
      }
    }
  }
}

@keyframes point {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0.125em);
  }
}

@include bp-down(md) {
  .tools-container {
    transform: translateY(-10rem);
    .tools-row {
      .card-hover {
        height: 14rem;
        h3 {
          font-size: 1.5rem;
        }
        h4 {
          font-size: 1rem;
          letter-spacing: 0;
          font-weight: 500;
        }
        .link-text {
          font-size: 1rem;
          transform: translateY(-3rem);
        }
      }
    }
  }
}
</style>
