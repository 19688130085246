import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Tools from '../views/Tools.vue';
import Infos from '../views/Infos.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/info',
    name: 'Infos',
    component: Infos,
  },
  {
    path: '/tools',
    name: 'Tools',
    component: Tools,
  },
  {
    path: '/exploration',
    name: 'Exploration',
    component: () => import('../views/Exploration.vue')
  },
  {
    path: '/assessment',
    name: 'Specific',
    component: () => import('../views/Specific.vue'),
  },
  {
    path: '/assessment/results',
    name: 'Results',
    component: () => import('../views/Results.vue'),
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'Home' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
