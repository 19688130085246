import Api from '@/utils/services/Api';

export default {
  state: {
    sectors: [],
    components: [],
    materials: [],
  },
  mutations: {
    setSectors(state, payload) {
      state.sectors = payload;
    },
    setComponents(state, payload) {
      state.components = payload;
    },
    setMaterials(state, payload) {
      state.materials = payload;
    },
  },
  actions: {
    setResults({ commit }, payload) {
      const sectors = payload.sectors;
      const components = payload.components;
      const materials = payload.materials;

      if (sectors.length) {
        const prod = JSON.stringify(sectors);
        localStorage.setItem('sectors', prod);
        commit('setSectors', sectors);
      }

      if (components.length) {
        const comp = JSON.stringify(components);
        localStorage.setItem('components', comp);
        commit('setComponents', components);
      }

      if (materials.length) {
        const mat = JSON.stringify(materials);
        localStorage.setItem('materials', mat);
        commit('setMaterials', materials);
      }
    },
    async getResults() {
      const selectedSectors = JSON.parse(localStorage.getItem('sectors'));
      const selectedComponents = JSON.parse(localStorage.getItem('components'));
      const selectedMaterials = JSON.parse(localStorage.getItem('materials'));

      const { sectors, components, materials } = await Api.post(
        'results',
        { type: 'results' },
        {
          selectedSectors: selectedSectors,
          selectedComponents: selectedComponents,
          selectedMaterials: selectedMaterials,
        }
      );

      return {
        sectors: sectors,
        components: components,
        materials: materials,
      };
    },
  },
  getters: {
    getSectors(state) {
      return state.sectors;
    },
    getComponents(state) {
      return state.components;
    },
    getMaterials(state) {
      return state.materials;
    },
  },
};
