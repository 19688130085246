import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/styles';
import GlobalComponents from './utils/globals';
import ElementPlus from 'element-plus';
import VueApexCharts from 'vue3-apexcharts';
import VueCookies from 'vue3-cookies';
import VueMatomo from 'vue-matomo';
import matomo from './utils/matomo';

const app = createApp(App);
app
  .use(store)
  .use(router)
  .use(GlobalComponents)
  .use(ElementPlus)
  .use(VueApexCharts)
  .use(VueCookies)
  .use(VueMatomo, {
    ...matomo,
    router: router,
  })
  .mount('#app');
