<template>
  <div class="container-fluid">
    <div class="row home-banner">
      <div class="col-12 text-center">
        <div class="text-banner">
          <h1>IRTC Decision Tool</h1>
          <h2 class="mt-4">
            Better understand and mitigate raw material criticality in your
            company
          </h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row py-5">
        <div class="col-12">
          <h3>About the tool</h3>
          <p>
            Starting in 2020, as the second phase of the IRTC Project,
            IRTC-Business focuses on supporting industries in the identification
            and management of Critical Raw Materials. This support is made
            through round tables, workshops, the publication of guidance
            documents, and the development of this web-tool for raw material
            criticality evaluation and mitigation.
          </p>
          <p>
            The Decision Tool has been developed to inform companies about
            potential material-related risks and possibilities to mitigate those
            risks. It is based on a cause-and-effect model that connects a
            company’s potential exposure to raw material criticality to
            well-known indicators that serve as early-warning signals.
          </p>
          <p>
            The Decision Tool is a visual and interactive version of this
            cause-and-effect model. Suggestions to mitigate risks are linked to
            the model based on the capacity of the measures to mitigate causes
            of risks, a company’s exposure to risks, or potential damages
            experienced by a company.
          </p>
          <div class="mt-4 mb-4">
            <button class="submit-btn" @click="showMaterials">
              Raw materials included in the tool
            </button>
          </div>
        </div>
        <div class="col-12 col-xl-8 img-center">
          <img
            class="img-fluid"
            src="https://www.irtc-conference.org/wp-content/uploads/2022/11/irtc-1.jpg"
            alt="IRTC Tool - Screenshot 1"
          />
        </div>
      </div>
      <div class="row py-5">
        <div class="col-12">
          <h3>User modes</h3>
          <p>
            The tool can be used in two modes: Exploration and a Specific
            Assessment.
          </p>
          <p>
            The exploration mode provides an overview of the problematics
            related to Critical Raw Materials. This mode allows you to explore
            the three main types of risks as brought forward during the IRTC
            workshops: problems with the physical accessibility of a raw
            material, strong price fluctuations, and reputation risks. The user
            can explore the underlying cause-and-effect model step by step and
            learn about the factors that contribute to those risks, what
            indicators can be used as early-warning signals, and how raw
            materials score on these indicators.
          </p>
          <p>
            The Specific Assessment allows you to select the company’s sector,
            specific products used or produced by the company, or specific raw
            materials. Following this selection, the tool provides an overview
            of relevant concerns for your business. It is explained how the
            dependency on raw materials may affect the company’s operations, and
            suggestions to mitigate the raw material risks are provided.
          </p>
        </div>
        <div class="col-12 col-xl-8 img-center mt-4">
          <img
            class="img-fluid"
            src="https://www.irtc-conference.org/wp-content/uploads/2022/11/irtc-2.jpg"
            alt="IRTC Tool - Screenshot 2"
          />
        </div>
      </div>
      <div class="row py-5">
        <div class="col-12">
          <h3>Upcoming features of the tool</h3>
          <p>
            The IRTC Decision Tool is continuously improved and updated.
            Furthermore, a scientific article that explains the model, the
            selected indicators, and the used data sources is in preparation.
          </p>
          <p>
            The following features are foreseen to be integrated into the tool:
          </p>
          <ul>
            <li>
              The addition of more raw materials, semi-finished products, and
              sectors
            </li>
            <li>
              Improved raw material factsheets, providing more contextual
              information about their supply and main uses
            </li>
            <li>Access to the data sources used in the tool</li>
            <li>
              Updating of LCA data for the calculation of environmental impacts
            </li>
            <li>
              Currently, the highest score between the mining and refining stage
              is integrated (the bottleneck). In the future, the scores for
              mining and refining will both be represented
            </li>
            <li>Graphical presentation of results, where relevant</li>
            <li>A variable threshold value in the Specific Assessment</li>
            <li>
              Integration of real-life examples to aid in the interpretation of
              the model and the outcomes
            </li>
            <li>A mobile-friendly version of the tool</li>
          </ul>
          <p>
            Do you have any suggestion on how to improve the tool? Please share
            it with us via the following survey.
          </p>
          <p>
            Sign up to the newsletter to stay informed about the latest updates!
          </p>
          <div class="mt-4">
            <a
              href="https://www.surveymonkey.com/r/IRTCWebtool"
              target="_blank"
              noreferrer
              ><button class="submit-btn mr-1">Give feedback</button></a
            >
            <a href="https://irtc.info/contact/" target="_blank"
              ><button class="submit-sec-btn mt-2">
                Subscribe to the newsletter
              </button></a
            >
          </div>
        </div>
      </div>
      <div class="row py-4">
        <div class="col-12">
          <h3>Sources</h3>
          <ul>
            <li v-for="(source, index) in sources" :key="index">{{ source }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="showModal">
    <template #title><h3 class="mb-0">Raw materials included in the tool</h3></template>
    <p>The following raw materials are currently included in the tool (November 14th, 2022): </p>
    <el-scrollbar max-height="350px" :always="true">
      <ul>
        <li v-for="material in materials" :key="material">{{ material }}</li>
      </ul>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="showModal = false"> Close </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import Api from '@/utils/services/Api';

export default {
  name: 'About',
  data() {
    return {
      showModal: false,
      materials: [],
      sources: []
    };
  },
  async created() {
    this.sources = await Api.get('sources', 'full');
  },
  methods: {
    async getMaterials() {
      this.materials = await Api.get('materials', 'names');
    },
    async showMaterials() {
      await this.getMaterials();
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 1rem;
  color: $secondary;
}
.img-fluid {
  width: 100%;
  height: auto;
}

ul {
  li {
    list-style: disc;
  }
}
</style>
