import api from '@/utils/api';

export default class Api {
  static async get(route, branch) {
    const { data } = await api.get(`/${route}?type=${branch}`);
    return data;
  }

  static async post(route, params, payload) {
    const { data } = await api.post(`/${route}`, payload, {
      params: { ...params },
    });
    return data;
  }
}
