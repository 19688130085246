<template>
  <header class="header">
    <div class="container">
      <nav class="navbar-container">
        <router-link :to="{ name: 'Home' }" class="navbar-logo">
          <img :src="require('@/assets/img/logo-irtc.png')" class="navbar-brand" alt="IRTC Logo"/>
        </router-link>
        <ul class="navbar-menu" :class="{ active: isActive }" @click="isActive = !isActive">
          <li class="navbar-item">
            <router-link :to="{ name: 'Home' }">
              Home
            </router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{ name: 'About' }">
              About
            </router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{ name: 'Tools' }">
              <button class="nav-btn">Start the tool</button>
            </router-link>
          </li>
        </ul>
        <div
          class="hamburger"
          @click="isActive = !isActive"
          :class="{ active: isActive }"
        >
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid $primary-70;
}

.navbar-brand {
  width: 5rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 1.25rem;
  height: 3px;
  margin: 0.25rem auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: $dark-text;
}

.navbar-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  align-items: center;
}

.navbar-item {
  margin-left: 2.5rem;
  a {
    color: $dark-text;
  }
  a:hover,
  .router-link-active,
  .router-link-exact-active {
    color: $primary;
  }
}

@include bp-down(md) {
  .navbar-menu {
    position: fixed;
    right: -100%;
    top: 4rem;
    flex-direction: column;
    background-color: white;
    width: 100%;
    border-radius: 0.5rem;
    text-align: center;
    transition: 0.3s;
    z-index: 1000;
  }
  .navbar-menu.active {
    right: 0;
  }
  .navbar-item {
    margin: 1.5rem 0;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 0.5rem;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
</style>
